<template>
  <div class="servidores-olt-placa-uplink-porta">
    <h1 class="titulo-card fs-4">Servidores / Gerenciamento OLT / Placa uplink / Porta</h1>
    <div v-if="(placa)" class="form-group card-principal">
      <div class="row justify-content-between">
        <div class="col mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="col mb-3">
          <div class="d-grid gap-2 d-md-flex justify-content-end">
            <button @click="btnAddVlan()" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Vlan
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <!-- <div class="d-flex justify-content-between">
          <div class="input-group mb-3">
            <input
                v-on:keyup.enter="handlePesquisa()"
                type="text"
                class="form-control"
                placeholder="Pesquisar"
                aria-label="Pesquisar"
                aria-describedby="button-addon2">
            <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
              <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
            </button>
          </div>
        </div> -->
        <div v-if="placa" class="row border-bottom">
          <p class="mb-0"><strong>Porta uplink:</strong> {{placa.endereco}}/{{porta}}</p>
        </div>

        <div class="d-flex">
          <table class="table table-sm-pc table-striped table-hover">
            <thead>
              <tr>
                <th width="25">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="selectAll" v-on:click="toggleCheckboxVlan()" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description"></span>
                  </label>
                </th>
                <th width="65">VLAN</th>
                <th>Tipo tag</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="vlan in vlans" :key="vlan.id">
                  <td scope="row">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" v-model="vlan._checked" class="custom-control-input">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </td>
                  <td>{{vlan.vlan}}</td>
                  <td>{{vlan.tipo_tag}}</td>
                </tr>
              </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 mb-2">
            <div class="d-grid gap-2 d-md-flex justify-content-end">
              <button type="button" v-bind:disabled="!podeExibirBtnExcluir" @click="btnExcluir()" class="btn btn-danger">
                <font-awesome-icon :icon="['fas', 'times']" fixed-width class="me-1"/>Remover
              </button>
            </div>
          </div>
        </div>
        <!-- <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/> -->
      </div>
    </div>

    <!-- modal-placa-uplink-add-vlan -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-placa-uplink-add-vlan" id="btn-modal-placa-uplink-add-vlan-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-placa-uplink-add-vlan" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Inserir nova VLAN
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="">Vlan</label>
                  <input type="number" v-model.number="modalAddVlan.form.vlan" v-on:keyup.enter="modalAddVlan.btnSalvar()" class="form-control" min="1" max="4095" placeholder="Número da VLAN">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-placa-uplink-add-vlan-fecha">Fechar</button>
            <button type="button"
                @click="modalAddVlan.btnSalvar()"
                v-bind:disabled="!modalAddVlan.podeSalvar()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-placa-uplink-add-vlan -->

  </div>
</template>

<script>
// import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
// import clone from '@/modules/clone'
// import model from '@/model'

export default {
  name: 'ServidoresOLTPlacaUplinkPorta',

  components: {
    // Paginador,
  },

  data() {
    return {
      oltID: undefined,
      placaID: undefined,
      porta: undefined,
      placa: undefined,
      vlans: [],
      selectAll: false,
      modalAddVlan: {
        form: {
          vlan: 0,
        },
        reset: this.modalAddVlanReset,
        podeSalvar: this.modalAddVlanPodeSalvar,
        btnSalvar: this.modalAddVlanBtnSalvar,
      },
    }
  },

  methods: {
    async init() {
      loading(true);

      // carrega placa
      await api.get(`/servidores/olt/${this.oltID}/placas/${this.placaID}`).then(res => {
        this.placa = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
        this.$router.go(-1);
        return;
      });

      // lista vlans
      await this.atualizaVlans().catch(() => {
        this.$router.go(-1);
        return;
      });

      loading(false);
    },

    async atualizaVlans() {
      return api.get(`/servidores/olt/${this.oltID}/placas-uplink/${this.placaID}/portas/${this.porta}/vlans`).then(res => {
        this.vlans = res.data.items;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    toggleCheckboxVlan() {
      this.vlans.forEach(x => {x._checked = !this.selectAll});
    },

    btnAddVlan() {
      document.getElementById('btn-modal-placa-uplink-add-vlan-abre').click();
      this.modalAddVlan.reset();
    },

    async btnExcluir() {
      const vlans = this.vlans.filter(x => x._checked === true);

      await dialogo.confirmacao(`Confirma excluir todas as ${vlans.length} vlans selecionadas?`).then(async () => {
        loading(true);
        await this.removeVlans(vlans);
        loading(false);
      });
    },

    async removeVlans(vlans) {
      var lastErr = undefined;
      var needUpdate = false;

      for (const x of vlans) {
        await api.delete(`/servidores/olt/${this.oltID}/placas-uplink/${this.placaID}/portas/${this.porta}/vlans/${x.id}`).then(() => {
          needUpdate = true;
        }).catch(error => {
          lastErr = error;
        });
      }

      if (!lastErr) {
        dialogo.alerta('Todas as VLANs foram removidas com sucesso.');
      } else {
        dialogo.erroHttp(lastErr);
      }
      if (needUpdate) {
        this.atualizaVlans();
      }
    },
    ///////////////////////////////////
    // modal-placa-uplink-add-vlan
    modalAddVlanReset() {
      this.modalAddVlan.form = {
        vlan: undefined,
      };
    },

    modalAddVlanPodeSalvar() {
      switch (true) {
        case (!this.modalAddVlan.form.vlan):
          return false;

        default:
          return true;
      }
    },

    modalAddVlanBtnSalvar() {
      if (!this.modalAddVlan.podeSalvar()) {
        return;
      }

      const payload = {
        vlan: this.modalAddVlan.form.vlan,
        tipo_tag: "ttTagged"
      };

      loading(true);
      api.post(`/servidores/olt/${this.oltID}/placas-uplink/${this.placaID}/portas/${this.porta}/vlans`, payload).then(() => {
        loading(false);
        document.getElementById('btn-modal-placa-uplink-add-vlan-fecha').click();
        this.atualizaVlans();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    // modal-placa-uplink-add-vlan
    ///////////////////////////////////

  },

  computed: {
    podeExibirBtnExcluir() {
      return this.vlans.some(x => x._checked === true);
    },
  },

  created() {
    const oltID = +this.$route.params.oltID;
    const placaID = +this.$route.params.placaID;
    const porta = +this.$route.params.porta;

    if (!oltID || !placaID || !porta) {
      this.$router.push({ path: `/configuracoes/servidores` });
      return;
    }

    this.oltID = oltID;
    this.placaID = placaID;
    this.porta = porta;
    this.init();
  },
}
</script>
